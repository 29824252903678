/* eslint-disable import/no-unresolved */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import Intercom from "@intercom/messenger-js-sdk";
import { userDetailsSelector } from "../../../src/store/slices/applicationFormSlice";

export default function IntercomChat() {
  const { boot, update } = useIntercom();
  const { isIntercomChatOptions } = useSelector(userDetailsSelector)

  useEffect(() => {
    if (isIntercomChatOptions?.showIntercom && isIntercomChatOptions?.intercomData) {
      const { intercomData } = isIntercomChatOptions

      const user = {
        id: intercomData?.user_id,
        name: intercomData?.name,
        email: intercomData?.email,
        // createdAt: new Date(),
      }

      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: user.id,
        name: user.name,
        email: user.email,
        // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });

      // For open window automatically
      // Wait for Intercom to load, then open chat with predefined message
      const openChatWithMessage = () => {
        if (window.Intercom) {
          window.Intercom("showNewMessage", `Hey it looks like you're having an issue with ${isIntercomChatOptions?.service}. We're looking into it as we speak and we'll comeback to you ASAP. In the meantime, if you have any questions, feel free to reply here.`);
        }
      };

      // Small delay to ensure Intercom is fully loaded
      setTimeout(openChatWithMessage, 1000);
    }

  }, [boot, update, isIntercomChatOptions]);

  useEffect(() => {
    boot();
  }, []);

  return null;
}